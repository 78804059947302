.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    padding-top: 150px;
    font-family: Arial, sans-serif;
    color: #717979;
    background-color: #ffffff;
    height: 100vh;
  }
  
  .contact-title {
    font-size: 50px;
    color: #364E4E;
    margin-bottom: 50px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 20px;
  }
  
  .contact-form label {
    font-size: 20px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    font-size: 18px;
    border: 2px solid #364E4E;
    border-radius: 10px;
  }
  
  .contact-form textarea {
    resize: vertical;
    height: 100px;
  }
  
  .contact-submit-button {
    padding: 15px;
    border: solid 3px #364E4E;
    border-radius: 10px;
    background-color: #B2D8C4;
    color: #364E4E;
    cursor: pointer;
    font-size: 18px;
    transition: all 0.3s ease;
  }
  
  .contact-submit-button:hover {
    background-color: #364E4E;
    color: #B2D8C4;
  }
  
  /* Media Query for mobile screens */
  @media screen and (max-width: 768px) {
    .contact-form {
      width: 80%; 
    }

  
    .contact-title {
      font-size: 30px; /* decrease title font size on smaller screens */
    }
  
    .contact-form label {
      font-size: 16px; /* decrease label font size on smaller screens */
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 16px; /* decrease input font size on smaller screens */
    }
  
    .contact-submit-button {
      font-size: 16px; /* decrease button font size on smaller screens */
    }
  }
  