/* General Header Styles */
.App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #B2D8C4;
}

.header-text {
    text-decoration: none; /* To remove underline from anchor tags */
    transition: all 0.3s ease;
    margin-left: 20px;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-text:hover {
    transform: scale(1.05); /* A subtle zoom effect on hover */
}

.logo{
    width: 150px;
  }
/* PRMUT Styles */
.header-title {
    font-size: 28px;
    font-weight: 600;
    color: #364E4E; 
    margin-right: 20px;
}

.header-title:hover {
    color: #364E4E;
}

/* Inmuebles Styles */
.header-inmuebles {
    font-size: 20px;
    font-weight: 500;
    color: #717979; 
    
}

.header-inmuebles2 {
    font-size: 20px;
    font-weight: 500;
    color: #717979;
    width: 170px; 
    text-align: center;
    
}

.header-inmuebles:hover {
    color: #364E4E;
}

.mobile-menu-button {
    display: none; /* Initially hidden */
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.header-right {
    display: flex;
    align-items: center;
    width: auto;

}

.header-right.open {
    max-height: 300px; /* Adjust this value based on your needs */
}

.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
/* For Responsive Design */
@media only screen and (max-width: 600px) {
    .App-header {
        flex-direction: column;
        padding: 10px 20px;
    }

    .header-right {
        margin-top: 10px;
    }

    .header-title,
    .header-inmuebles {
        margin: 5px 0;
    }
    .header-inmuebles2{
        text-align: left;
    }
    .mobile-menu-button {
        display: block;
    }
    .header-right {
        display: none; /* Hide the menu by default on mobile */
    }
    .header-right.open {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the start */
        margin-top: 10px; /* Space between header and menu items */
    }
}
