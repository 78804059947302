/* NFT.css */
.App-header{
    display: flex;
    justify-content: space-between;
  }
  
.nftCard {
    display: flex;
    flex-direction: row;
    margin: 50px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #ffffff;
    border-width: 2px;
    padding: 2rem;
    justify-content: space-between;
    align-items: flex-start;
    font-family: Arial, 'sans-serif';
}

.nftVideo-container {
    position: relative;
    width: 50%; /* Adjust this as per your need */
    height: 20%; 
    overflow: hidden;
    padding-bottom: 46.25%; /* 16:9 Aspect Ratio */
    border-radius: 10px;
}

.title-price2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nftVideo-container > iframe {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

.nftCard-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 700;
    width: 45%; /* Adjust this as per your need */
}

.title-price2 > h1 {
    margin: 0;
    font-size: 5rem; /* Adjust this as per your need */
    font-weight: 700;
    color: #364E4E;
}

.nftCard-content > h2 {
    margin: 0;
    font-size: 2rem; 
    color: #555;/* Adjust this as per your need */
}

.title-price2 > p {
    margin: 0;
    font-size: 3rem;
    font-weight: 600;
    color: hwb(180 21% 69%);; /* Adjust this as per your need */
}

.nftCard-content > p {
    margin-top: 100px;
    color: #364E4E;
}

.nftLocation {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 70%;
    color: #555;
}

.nftLocation > p {
    margin: 0;
    font-size: 10px;
}

.title-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nftDetails {
    margin-top: 1rem;
}

.nftDetails > h2 {
    font-size: 1.5rem;
    color: #333;
}

.nftDetails > h3 {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;
}

.nftButtons {
    display: flex;
    margin-top: 1rem;
}

.nftButton {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #364E4E;
    background-color: #B2D8C4;
    border: solid 2px #364E4E;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1rem;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.nftButton:hover {
    background-color: rgb(54, 78, 78);
    color: #B2D8C4;
}

.openseaButton {
    width: 200px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.openseaButton img {
    width: 70%;
    height: auto;
}


@media screen and (max-width: 800px) {
    .nftCard {
      flex-direction: column;
      margin: 10px;
      padding: 1rem;
    }

    .nftVideo-container,
    .nftCard-content {
      width: 100%;
    }

    .nftCard-content > h1 {
      font-size: 3rem;
    }

    .nftCard-content > h2,
    .nftDetails > h2,
    .nftDetails > h3 {
      font-size: 1.2rem;
    }

    .title-price2 > p {
      font-size: 2rem;
    }

    .nftButtons {
      flex-wrap: wrap;
      gap: 10px;
    }

    .nftButton {
      padding: 5px 10px;
      font-size: 0.9rem;
      margin-right: 5px;
    }

    .openseaButton {
      width: 120px;
    }

    .openseaButton img {
      width: 100%;
    }
}
  
@media screen and (max-width: 500px) {
    .nftCard {
      margin: 10px auto;
      padding: 0.5rem;
    }

    .nftCard-content > h1 {
      font-size: 2rem;
    }

    .nftCard-content > h2,
    .nftDetails > h2,
    .nftDetails > h3 {
      font-size: 1rem;
    }

    .title-price2 > p {
      font-size: 1.5rem;
    }

    .nftButton {
      padding: 5px;
      font-size: 0.8rem;
      margin-right: 3px;
    }

    .openseaButton {
      width: 100px;
    }
}