/* Gallery.css */
.App-header{
  display: flex;
  justify-content: space-between;
}
/* Grid Layout for Gallery */
.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 10rem;
    padding-top: 2rem;
    box-sizing: border-box;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
}
  
/* Card Style */
.card {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    border: #312600;
    border-style: solid;
    background-color: #fff;
    border-width: 2px;
}
  
  .card > video {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 700;
  }
  
  .card-content > h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .card-content > p {
    margin: 0;
  }
  
  .link {
    display: inline-block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #364E4E;
    border: solid 3px #364E4E;
    border-radius: 10px;
    background-color: #B2D8C4;
    text-decoration: none;
    align-self: center;
    text-align: center;
    transition: background-color 0.3s ease;
}

.link:hover {
    background-color: #364E4E;
    color: #B2D8C4;
}

  .video-container {
    position: relative;
    top:0;
    left: 0;
    width: 100%;
    height: 0; /* Adjust the height as per your requirement */
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
}

.video-container > iframe {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}
  
.location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 70%;
}

.location > p {
    margin: 0;
    font-size: 10px;
}

.title-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .gallery {
        margin-top: 30px;
      grid-template-columns: repeat(2, 1fr);
      padding: 5rem;
      justify-content: center;
    }
    .card {
      min-width: 250px; /* Adjust as needed */
    }
  }
  
  @media screen and (max-width: 500px) {
    .gallery {
      grid-template-columns: 1fr;
      padding: 3rem;
      justify-content: center;
    }
    .card {
      min-width: 300px; /* Adjust as needed */
    }
  }