.homepage {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: Arial, sans-serif; 
  color: #717979; /* Main text color */
  line-height: 1.6;
  background-color: #ffffff; /* Background color */
}

.section-1 {
  display: flex; 
  justify-content: space-between; 
  align-items: top; 
  margin-bottom: 200px;
  padding: 100px;
}



.home-button {
  padding: 22px 50px;
  border: solid 3px #364E4E;
  border-radius: 10px;
  background-color: rgb(178, 216, 196);
  color: #364E4E;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease;
  font-weight: 600;
}

.connect-button {
  padding: 25px 60px;
  border: solid 3px rgb(178, 216, 196);
  border-radius: 10px;
  background-color: #364E4E;
  color:rgb(178, 216, 196);
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s ease;
  font-weight: 600;
  margin-left: 10px;
}

.home-button:hover {
  background-color: #364E4E;
  color: #B2D8C4;
}

.section-2 {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
}

.section-1-content {
  max-width: 50%;
}

.section-1-image {
  max-width: 100%; 
  height: auto; 
  border-radius: 10px;
  margin-left: -80px;
  margin-top: -20px;
  border: #364E4E solid 3px;
}

.section-2-image {
  max-width: 40%; 
  height: auto; 
  border-radius: 10px;
  border: #364E4E solid 3px;
}

.section-3-image {
  max-width: 70%; 
  height: auto; 
  border-radius: 10px;
  border: #364E4E solid 3px;
}


.image1-container{
  background-color: #B2D8C4;
  border: #364E4E 3px solid;
  max-width: 40%; 
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.left-title,
.center-title {
  font-size: 70px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #364E4E; /* Title color */
}

.left-subtitle{
  margin-bottom: 160px;
  font-size: 24px;
  color: #717979; /* Subtitle color */
}
.center-subtitle {
  font-size: 24px;
  margin-bottom: 40px;
  color: #717979; /* Subtitle color */
}

.left-title {
  text-align: left;
  font-size: 70px;
  line-height: 70px;
}

.left-subtitle {
  text-align: left;
}

.center-title {
  text-align: center;
}

.center-subtitle {
  text-align: center;
  padding-left: 250px;
  padding-right: 250px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 18px;
  color: #364E4E;
  font-weight: 600;
}

.grid-item > h4 {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.grid-item {
  width: 30%;
  text-align: center;
  margin-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
}



.grid-item img {
  width: 50%;
  height: auto;
}

footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* Transparent color */
  color: #364E4E; /* Footer text color */
  text-align: center;
  box-sizing: border-box; /* Makes sure the width includes padding */
}

.footer-text {
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, sans-serif; 
}

.footer-content {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 50px;
}

.footer-content2 {
  display: flex;
  flex-direction: column;
}

.footer-subtitle {
  color: #364E4E;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  gap: 20px; /* adjust this value to set the distance between your icons */
}

.social-icons img {
  width: 60px; /* adjust this value to set the size of your icons */
  height: auto;
}

.gallery{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-help {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.724), rgba(0, 0, 0, 0.674)), url('./bgImage.jpg');
  background-size: cover; 
  background-position: center; 
  padding-left: 50px;
  padding-right: 40px;
}


.help-text {
  max-width: 50%;
}

.help-title {
  color: #ffffff;
  font-size: 80px;
 
}

.help-subtitle {
  color: #ffffff;
  font-size: 30px;
}

.help-button {
  padding: 30px 60px;
  border-radius: 10px;
  border: none;
  background-color: #B2D8C4; /* Update with the color you prefer */
  color: #364E4E; /* Update with the color you prefer */
  cursor: pointer;
  font-size: 30px;
  transition: all 0.3s ease;
}

.help-button:hover {
  background-color: #364E4E;
  border: solid 3px #B2D8C4;
  color: #B2D8C4; /* Update with the color you prefer when hover */
}




@media only screen and (max-width: 600px) {
  
  .homepage {
    padding: 0 15px; /* added some padding to avoid sticking content to the edges */
  }

  .section-1 {
    flex-direction: column;
    padding: 20px;
    margin-bottom: 100px; /* reduce bottom margin */
  }
  
  .section-1-content {
    max-width: 100%;
    text-align: center;
  }
  .image1-container{
    max-width: 150%;
    margin-left: 0;
    margin-top: 20px;
    background-color: transparent;
    border-color: transparent;
  }
  .section-1-image {
    max-width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .section-1-image {
    margin: 20px 0; /* give some space between image and content */
  }

  .section-2-image {
    max-width: 90%; 
    margin-bottom: -100px;/* give some space between image and content */
  }
   .section-3-image {
    max-width: 90%; 
    margin-bottom: -100px;/* give some space between image and content */
  }

  .left-title,
  .center-title {
    font-size: 30px;
    line-height: normal;
  }

  .left-subtitle,
  .center-subtitle {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px; /* reduce the bottom margin */
  }

  .grid {
    flex-direction: column;
  }

  .grid-item {
    width: 100%;
    padding: 20px 0;
    margin-bottom: 30px; /* reduced bottom margin */
  }

  .grid-item img {
    width: 50%;
    margin: 0 auto; /* center the images */
  }

  

  .home-button{
    padding: 15px 30px; /* reduce padding for buttons */
    font-size: 16px; /* decrease font size */
  }
  .connect-button {
    padding: 15px 30px; /* reduce padding for buttons */
    font-size: 16px;
    min-width: 150px;
  max-width: 150px; /* decrease font size */
  }

  .connect-button {
    margin-left: 0;
    margin-top: 15px; /* add some margin between buttons */
  }

  footer {
    flex-direction: column;
    padding: 20px 0;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .footer-content2 {
    flex-direction: column;
    align-items: center;
  }

  .footer-subtitle {
    margin-top: 20px;
  }

  .social-icons img {
    width: 50px;
    height: auto;
  }

  .section-help {
    flex-direction: column;
    padding: 20px 15px;
  }

  .help-text {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px; /* space between text and button */
  }

  .help-title {
    font-size: 40px;
  }

  .help-subtitle {
    font-size: 24px;
  }

  .help-button {
    font-size: 24px;
    padding: 20px 40px;
  }

  .home-button{
    font-size: 10px;
    height: 50px;
    margin-top: 17px;
    margin-right: 20px;
  }

  .section-1 {
    flex-direction: column;
  }
}
